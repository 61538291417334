import axios from "axios";

const PREFERENCES = {
    VIEW_AS_GRID: { key:'view-as-grid', def:'true'},
    USE_TURBO: { key:'use-turbo', def:'true'},
    LOCK_MARKER_SUMMARY: { key:'lock-marker-summary', def:'1296'},
    LAST_CONTEXT: { key:'last-context', def:'-1'},
    PAGE_SIZE: { key:'page-size', def:'8'},
    CLIP_TIME: { key:'clip-time', def:'5'},
    MARKERS_COLLAPSED: { key:'markers-collapsed', def:'false'},
    VERT_FAV_BAR_HEIGHT: { key:'vert-fav-bar-height', def:'460'},
    HORZ_FAV_BAR_WIDTH: { key:'horiz-fav-bar-width', def:'640'},
    MARKER_SUMMARY_LEFT: { key:'marker_summary_left', def:'64px'},
    MARKER_SUMMARY_TOP: { key:'marker_summary_top', def:'128px'},
    MARKER_SUMMARY_OPACITY: { key:'marker_summary_opacity', def:'0'},
    PLAYER_FAVBAR_PRESENTATION: { key: 'player_favbar_presentation', def:'0'},
    PLAYER_DEFAULT_OFFSET_BEFORE: { key: 'player_default_offset_before', def:'2000'},
    PLAYER_DEFAULT_OFFSET_AFTER: { key: 'player_default_offset_after', def:'2000'},
}

const getPreference = (pref) => {
    return privateGetPreference(pref.key, pref.def)
}

const getPreferenceAsBool = (pref) => {
    return privateGetPreference(pref.key, pref.def) === 'true';
}

const getPreferenceAsNumber = (pref) => {
    return Number(privateGetPreference(pref.key, pref.def));
}

function loadUserPreferences() {
    axios.get('/users/preferences')
        .then(resp => {
            const userPreferences = JSON.parse(resp.data.userPreferences);
            console.log('Loading preferences...', userPreferences);
            for(const pref in userPreferences) {
                localStorage.setItem(pref, userPreferences[pref] || PREFERENCES[pref].def);
            }
        })
        .catch(err => console.error('Error getting preferences', err));
}

const savePreference = (pref, value) => {
    localStorage.setItem(pref.key, value);
    saveUserPreferences(pref.key);
}

const savePreferences = (prefs) => {
    prefs.forEach(p => {
        localStorage.setItem(p.pref.key, p.value);
    });
    saveUserPreferences('multiple-preferences');
}

// "private" function
function saveUserPreferences(preference) {
    console.log('Saving due to changes on: ' + preference);
    const newPreferences = new Map();
    for (let pref in PREFERENCES) {
        pref = PREFERENCES[pref];
        const val = privateGetPreference(pref.key, pref.def);
        newPreferences.set(pref.key, val);
    }
    const obj = {};
    newPreferences.forEach((val, key) => obj[key] = val);
    const userPreferences = JSON.stringify(obj);
    console.log('Saving:', obj);
    return axios.put('/users/preferences', { userPreferences });
}

// "private" function
const privateGetPreference = (key, defaultValue) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue == null || savedValue === 'null' || savedValue === 'undefined') {
        console.log(`Using default value: ${defaultValue}`);
        return defaultValue;
    }
    return savedValue;
}

export {
    PREFERENCES, loadUserPreferences, savePreference, savePreferences,
    getPreference, getPreferenceAsBool, getPreferenceAsNumber
}
