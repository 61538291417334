import moment from "moment";
import * as tz from "moment-timezone";

function setupUtils() {
    console.log('Timezone works correctly? ' + (tz !== undefined));
}

function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

function convertSecondsToTime(timeInSeconds) {
    if(timeInSeconds < 0) {
        return '00:00';
    }
    const finalHours = Math.floor(timeInSeconds / 3_600);
    const finalMinutes = Math.floor((timeInSeconds - finalHours * 3_600)/60);
    const finalSeconds = Math.ceil(timeInSeconds - finalHours * 3_600 - finalMinutes * 60);
    let detailTime = finalHours ? `${finalHours}:` : '';
    detailTime += (finalMinutes < 10 ? '0' : '' ) + `${finalMinutes}:`;
    detailTime += (finalSeconds < 10 ? '0' : '' ) + `${finalSeconds}`;
    return detailTime;
}

function convertMillisecondsToTime(timeInMilliseconds, show = true, text = '') {
    if (timeInMilliseconds === undefined || timeInMilliseconds <= 0) {
        return '00:00:000';
    }
    const totalSeconds = Math.floor(timeInMilliseconds / 1000);
    const finalMilliseconds = Math.floor(timeInMilliseconds % 1000);
    const finalHours = Math.floor(totalSeconds / 3_600);
    const remainingSecondsAfterHours = totalSeconds % 3600;
    const finalMinutes = Math.floor(remainingSecondsAfterHours / 60);
    const finalSeconds = remainingSecondsAfterHours % 60;

    let detailTime = '';

    detailTime += finalHours ? `${finalHours}:` : '';
    detailTime += (finalMinutes < 10 ? '0' : '' ) + `${finalMinutes}:`;
    detailTime += (finalSeconds < 10 ? '0' : '' ) + `${finalSeconds}`;

    if (show) {
        if (finalMilliseconds < 10) {
            detailTime += (':00' + finalMilliseconds);
        } else if (finalMilliseconds < 100) {
            detailTime += (':0' + finalMilliseconds);
        } else {
            detailTime += (':' + finalMilliseconds);
        }    
    }
    
    return detailTime;
}

function isValidTextInMilli(timeText) {
    const parts = timeText.split(':');

    if (parts.length < 2 || parts.length > 4) {
        return false;
    }
    return true;
}

function isNumericText(hours, minutes, seconds, milliseconds) {
    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds) || isNaN(milliseconds)) {
        return false;
    }

    return true;
}

function isValidFormat(input)  {
    const regexSsAaa = /^\d{2}:\d{3}$/;
    const regexMmSsAaa = /^\d{2}:\d{2}:\d{3}$/;
    const regexHhMmSsAaa = /^\d{2}:\d{2}:\d{2}:\d{3}$/;
    return regexSsAaa.test(input) || regexMmSsAaa.test(input) || regexHhMmSsAaa.test(input);
  };

function convertTextToMilliseconds(timeText) {
    const valid = isValidTextInMilli(timeText);
    if (!isValidFormat(timeText)) {
        return false;
    }
    if (valid) {
        const parts = timeText.split(':');
        let hours = 0, minutes = 0, seconds = 0, milliseconds = 0;

        if (parts.length === 4) {
            if (parts[0].length !== 2 || parts[1].length !== 2 || parts[2].length !== 2 || parts[3].length !== 3) {
                return false;
            }
            hours = parseInt(parts[0],10);
            minutes = parseInt(parts[1],10);
            seconds = parseInt(parts[2],10);
            milliseconds = parseInt(parts[3],10);
        } else if (parts.length === 3) {
            if (parts[0].length !== 2 || parts[1].length !== 2 || parts[2].length !== 3) {
                return false;
            }
            minutes = parseInt(parts[0],10);
            seconds = parseInt(parts[1],10);
            milliseconds = parseInt(parts[2],10);
        } else if (parts.length === 2) {
            if (parts[0].length !== 2 || parts[1].length !== 3) {
                return false;
            }
            seconds = parseInt(parts[0],10);
            milliseconds = parseInt(parts[1],10);
        }

        if (isNumericText(hours,minutes,seconds,milliseconds)) {
            const totalMilliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000 + milliseconds;
            return Math.max(totalMilliseconds, 0);
        }
    }

    return false;
}


function presentDate(dateAsString, timezone) {
    if (timezone) {
        console.log(moment(dateAsString+"-00:00").tz(timezone).format());
    }
    return moment.duration(-moment().diff(moment(dateAsString+"-00:00"))).humanize(true);
}

function fixDate(dateAsString, timezone) {
    const format = 'yyyy-MM-DD HH:mm:ss';
    if (timezone) {
        return moment(dateAsString+"-00:00").tz(timezone).format(format);
    }
    return moment(dateAsString+"-00:00").format(format);
}

setupUtils();
export { getRandomColor, convertSecondsToTime, convertMillisecondsToTime, presentDate, fixDate, convertTextToMilliseconds };
